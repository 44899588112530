
import { Component, Input, OnInit } from "@angular/core";
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormGroup
} from "@angular/forms";
import { errorsMessages } from "@config";

@Component({
	selector: "boost-input",
	imports: [FormsModule, ReactiveFormsModule],
	standalone: true,
	template: `
		<div class="boost-input_form-group" [formGroup]="form">
		  <label>{{ label }}</label>
		  <div class="{{ showToggleButton ? 'hasIcon' : '' }}">
		    <input
		      [type]="type"
		      class="boost-input_form-control"
		      [autocomplete]="autocomplete ? autocomplete : controlName"
		      [formControlName]="controlName"
		      [autofocus]="autofocus"
		      />
		    @if (showToggleButton) {
		      <div
		        (click)="togglePassWord()"
		        class="boost-input_form-control-icon"
		        >
		        @if (!showPassWord) {
		          <span class="material-symbols-rounded">
		            visibility
		          </span>
		        }
		        @if (showPassWord) {
		          <span class="material-symbols-rounded">
		            visibility_off
		          </span>
		        }
		      </div>
		    }
		  </div>
		  @if (
		    form.get(controlName)?.invalid &&
		    (form.get(controlName)?.touched || form.get(controlName)?.dirty)
		    ) {
		    <p
		      >
		      <span>{{ errorMessage() }}</span>
		    </p>
		  }
		</div>
		`
})
export class BoostInputComponent implements OnInit {
	@Input() form!: UntypedFormGroup;
	@Input() controlName!: string;
	@Input() label!: string;
	@Input() autofocus!: boolean;
	@Input() autocomplete!: string;
	@Input() type!: "text" | "email" | "password" | "date";

	public showToggleButton: boolean = false;
	public showPassWord: boolean = false;
	private control!: AbstractControl;
	public errorsMessages: any;
	public defaultMessage = errorsMessages["default"];
	ngOnInit() {
		this.showToggleButton = this.type === "password";
		this.errorsMessages = errorsMessages[this.controlName];
		this.control = this.form.controls[this.controlName];
	}

	public togglePassWord() {
		this.showPassWord = !this.showPassWord;
		this.type = this.type === "password" ? "text" : "password";
	}
	public errorMessage = (): string => {
		if (!this.errorsMessages) return this.defaultMessage;
		const controlErrors = this.control?.errors;
		if (!controlErrors) return "";
		const errorName = Object.keys(controlErrors)[0].toLowerCase();
		if (!errorName) return this.defaultMessage;
		return this.errorsMessages[errorName] || this.defaultMessage;
	};
}
