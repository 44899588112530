export const config = {
	funds: {
		compare: {
			number_max: 6
		}
	},
	global: {
		popin: {
			failedConnection: false
		}
	}
};

export const errorsMessages: any = {
	nickname: {
		required: "Vous n'avez pas indiqué de pseudo"
	},
	email: {
		required: "Vous n'avez pas indiqué d'email",
		email: "L'email est incorrect"
	},
	password: {
		required: "Vous n'avez pas indiqué de mot de passe",
		minlength: "Le mot de passe doit faire au moins 6 charactères",
		equalpasswords: "Les mots de passes doivent être identiques"
	},
	repassword: {
		required: "Vous n'avez pas indiqué de mot de passe",
		minlength: "Le mot de passe doit faire au moins 6 charactères",
		equalpasswords: "Les mots de passes doivent être identiques"
	},
	cgu: {
		required: "Vous devez valider les CGU"
	},
	select: {
		required: "Vous devez selectionner une option"
	},
	default: "Vous devez corriger ce champ "
};

export type ErrorsMessageKeys = keyof typeof errorsMessages;
